import React, { useEffect, useState } from 'react'
import { Screen } from './components'

const Sandbox = props => {

    return (
        <Screen secure {...props}>

        </Screen>
    )
}

export default Sandbox