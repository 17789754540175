// import ActivityIndicator from './NOT_IN_USE/ActivityIndicator'
// import AddressView from './BugForm'
// import BugForm from '../presentations/BugList/components/BugForm'
import BugNavBar from './BugNavBar'
import AdminButton from './AdminButton'
import Cabinet from './Cabinet'
import CenteredContainer from './CenteredContainer'
import Checkbox from './Checkbox'
// import Counter from './Counter'
import FeedNavBar from './NavBar'
import IconButton from './IconButton'
import IconButtonLarge from './IconButtonLarge'
import ImageContained from './ImageContained'
// import ImageClone from './NOT_IN_USE/ImageClone'
import ImageList from './ImageList'
import ImageShowcase from './ImageShowcase'
import ImageUploadIndicator from './ImageUploadIndicator'
import ModalContainer from './ModalContainer'
import NavBar from './NavBar'
// import InfoMarker from './InfoMarker'
// import ProfileNav from './ProfileNav'
import PostForm from './PostForm'
import Scrollable from './Scrollable'
import Settings from './Settings'
import SimpleButton from './SimpleButton'
import StatusIndicator from './StatusIndicator'
// import Slider from './Slider'
// import SplitScreen from './SplitScreen'
// import Switch from './Switch'
import TextCopy from './TextCopy'
// import ThumbList from './ThumbList'
import Time from './Time'
import UserAvatar from './UserAvatar'
import UserImages from './UserImages'
import UserList from './UserList'
// import UserNavBar from './UserNavBar_old'
// presentations

import AuthForm from './AuthForm'
import BugList from './BugList'
import Feed from './Feed'
import Form from './Form'
import ImagePicker from './ImagePicker'
// import ImagePickerMini from './NOT_IN_USE/ImagePickerMini'
import JobList from './JobList'
import ModalFactory from './ModalFactory'
import Notification from './Notification'
import Play from './Play'
import Socket from './Socket'

export {
    // ActivityIndicator,
    AdminButton,
    AuthForm,
    // BugForm,
    BugNavBar,
    Cabinet,
    CenteredContainer,
    Checkbox,
    FeedNavBar,
    IconButton,
    IconButtonLarge,
    ImageContained,
    // ImageClone,
    ImageList,
    ImageShowcase,
    ImageUploadIndicator,
    ModalContainer,
    NavBar,
    PostForm,
    Settings,
    Scrollable,
    SimpleButton,
    Socket,
    StatusIndicator,
    TextCopy,
    Time,
    UserAvatar,
    UserImages,
    UserList,
    // UserNavBar,
    
    BugList,
    Feed,
    Form,
    ImagePicker,
    // ImagePickerMini,
    JobList,
    ModalFactory,
    Notification,
    Play,
}